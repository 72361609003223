import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    [
      !_vm.pageLoading
        ? _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3 mb-5",
              attrs: { icon: "mdi-domain", inline: "", color: "secondary" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "after-heading",
                    fn: function () {
                      return [
                        _c("h1", { staticClass: "h5" }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.edit
                                  ? _vm.$t("edit") +
                                    " " +
                                    _vm.managerCompanyData.name
                                  : _vm.$t("add") +
                                    " " +
                                    _vm.$t("manager_company")) + " "
                              )
                            ),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                777421120
              ),
            },
            [
              _c(
                VCardText,
                [
                  _c(
                    VForm,
                    {
                      ref: "form",
                      on: {
                        submit: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.checkForm()
                        },
                      },
                    },
                    [
                      _c(VTextField, {
                        attrs: { label: _vm.$t("name"), rules: [_vm.required] },
                        model: {
                          value: _vm.managerCompanyData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.managerCompanyData, "name", $$v)
                          },
                          expression: "managerCompanyData.name",
                        },
                      }),
                      _c("div", { staticClass: "cropper-and-logo-container" }, [
                        _c("div", { staticClass: "mt-5" }, [
                          _c("p", { staticClass: "colorpickerTitle" }, [
                            _vm._v(_vm._s(_vm.$t("logo"))),
                          ]),
                          _c("input", {
                            ref: "imageLogoInput",
                            staticClass: "ml-0",
                            attrs: {
                              id: "imageLogoInput",
                              name: "imageLogoInput",
                              type: "file",
                            },
                            on: { change: _vm.setImageLogo },
                          }),
                          _c(
                            "p",
                            { staticClass: "alert-size-image-message mt-1" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("alert_size_image")) + " "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "cropper-container" },
                          [
                            _c(VCheckbox, {
                              attrs: { "hide-details": "" },
                              model: {
                                value: _vm.isCropSelected,
                                callback: function ($$v) {
                                  _vm.isCropSelected = $$v
                                },
                                expression: "isCropSelected",
                              },
                            }),
                            _c("span", [_vm._v(_vm._s(_vm.$t("crop")))]),
                            _c(
                              VTooltip,
                              {
                                staticStyle: { "align-items": "end" },
                                attrs: { left: "", "max-width": "300px" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            VBtn,
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "white",
                                                    elevation: "0",
                                                    icon: "",
                                                    dark: "",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                VIcon,
                                                { attrs: { color: "primary" } },
                                                [_vm._v("mdi-help-circle")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1911376209
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("crop_description"))),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm.isCropSelected == true && _vm.imagePath
                        ? _c(
                            "div",
                            [
                              _c("cropper", {
                                staticClass: "cropper",
                                staticStyle: {
                                  "max-width": "483px",
                                  height: "210px",
                                },
                                attrs: { src: _vm.imagePath },
                                on: { change: _vm.change },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.imagePath && !_vm.isCropSelected
                        ? _c("div", [
                            _c("img", {
                              attrs: {
                                id: "selected-company-logo",
                                src: _vm.imagePath,
                              },
                            }),
                          ])
                        : _vm._e(),
                      _c(
                        VCardActions,
                        { staticClass: "pl-0 dxa_modal_actions" },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "dxa_modal_btnSuccess",
                              attrs: {
                                color: "primary",
                                "min-width": "100",
                                loading: _vm.loading,
                                type: "submit",
                                disabled: !_vm.imageSizeIsValid,
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("save")))]
                          ),
                          _c(
                            VBtn,
                            {
                              staticClass: "dxa_modal_btnError",
                              attrs: { color: "secondary", "min-width": "100" },
                              on: { click: _vm.goBack },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("go_back")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _vm.savedContent
                        ? _c(
                            VAlert,
                            { staticClass: "ma-2", attrs: { type: "success" } },
                            [_c("span", [_vm._v(_vm._s(_vm.$t("saved")))])]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "box-loading" },
            [
              _c(VProgressCircular, {
                attrs: { indeterminate: "", size: "70", color: "primary" },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }